<template>
  <div>
    <validation-observer ref="SPRForm">
      <b-form>
        <!-- Header submit Button -->
        <b-card class="sticky-header-control">
          <b-row>
            <b-col class="d-flex align-items-center" md="6">
              <h3 class="with-separitor m-0 p-0">
                {{ $t(`g.addServicePurchaseRequest`) }} |
              </h3>
              <b-breadcrumb :items="breadCrumbItems" />
            </b-col>
            <b-col md="6" class="justify-content-end d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="validateForm"
                class="mr-1 ml-1"
              >
                {{ $t("g.submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <!-- ** Header submit Button -->

        <!-- basic information -->
        <b-card :title="$t('g.basicInformations')">
          <b-row>
            <!-- Lab Name -->
            <b-col md="4">
              <b-form-group :label="$t('g.labName')">
                <validation-provider
                  #default="{ errors }"
                  name="lab"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.lab_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="lab_name_options"
                    text-field="name"
                    value-field="id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Lab Name -->

            <!-- Provider name -->
            <b-col md="4">
              <b-form-group :label="$t('g.providerName')">
                <validation-provider
                  #default="{ errors }"
                  name="provider name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.supplier_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheProviderNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Provider name -->
            <!-- Contact person -->
            <!-- <b-col md="4">
              <b-form-group :label="$t('g.contactPerson')">
                <validation-provider
                  #default="{ errors }"
                  name="contact person"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.contact_person"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheContactPersonHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- ** Contact person -->
            <!-- Phone Number -->
            <b-col md="4">
              <b-form-group :label="$t('g.phoneNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="phone number"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.phone_number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterThePhoneNumberHere')"
                    type="number"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Phone Number -->
            <!-- Email -->
            <b-col md="4">
              <b-form-group :label="$t('g.email')">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    type="email"
                    v-model="form_data.email"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheEmailHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Email -->
            <!-- Address -->
            <!-- <b-col md="4">
              <b-form-group :label="$t('g.address')">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.address"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheAddressHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- ** Address -->
            <!-- Deadline for response -->
            <!-- <b-col md="4">
              <b-form-group :label="$t('g.deadlineForResponse')">
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.dead_line_for_response"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.enterTheDeadlineForResponseHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- ** Deadline for response -->
            <!-- Date -->
            <b-col md="4">
              <b-form-group label-for="date" :label="$t('g.lastResponsedate')">
                <validation-provider
                  #default="{ errors }"
                  name="last response date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.last_response_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Date -->
          </b-row>
        </b-card>
        <!-- ** basic information -->
        <!-- Requset Type -->
        <b-card :title="$t('g.typeOfRequest')">
          <b-row>
            <!-- Type Of Request -->
            <b-col md="4">
              <b-form-group :label="$t('g.typeOfRequest')">
                <validation-provider
                  #default="{ errors }"
                  name="request type"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.buy_request_type_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="type_of_request_options"
                    text-field="name"
                    value-field="id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Type Of Request -->
            <!-- Request Number -->

            <!-- <b-col md="4">
              <b-form-group :label="$t('g.requestNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="request number"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.request_number"
                    disabled
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheRequestNumberHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- ** Request Number -->
            <!-- Proof of competence -->
            <b-col md="12">
              <b-form-group :label="$t('g.proofOfCompetence')">
                <validation-provider
                  #default="{ errors }"
                  name="proof of competence"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterTheproofOfCompetenceHere')
                    "
                    v-model="form_data.proof"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Proof of competence -->
          </b-row>
          <!-- Item prices -->
        </b-card>
        <!-- ** Requset Type -->
        <!-- Item prices -->
        <b-card ref="card" :style="{ height: trHeight }">
          <b-card-title>
            <div class="d-flex justify-content-between align-itmes-center">
              <div>
                {{ $t("g.itemPrices") }}
              </div>
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{ $t("g.addItem") }}</span>
                </b-button>
              </div>
            </div>
          </b-card-title>
          <div>
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in form_data.buy_request_line"
              :id="item.id"
              :key="index"
              ref="row"
            >
              <!-- Item Descriptions  -->

              <b-col md="3">
                <b-form-group
                  :label="$t('g.itemDescriptions')"
                  label-for="item-descriptions"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`item descriptions ${item.id}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.desc"
                      type="text"
                      :placeholder="
                        $t('g.placeholder.enterTheItemDescriptionsHere')
                      "
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- ** Item Descriptions  -->

              <!-- Item specifications -->
              <b-col md="3">
                <b-form-group
                  :label="$t('g.itemSpecifications')"
                  label-for="item-specifications"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`item specifications ${item.id}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.spec"
                      type="text"
                      :placeholder="
                        $t('g.placeholder.enterTheItemspecificationsHere')
                      "
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- ** Item specifications -->

              <!-- Count -->

              <b-col md="3">
                <b-form-group :label="$t('g.count')" label-for="count">
                  <validation-provider
                    #default="{ errors }"
                    :name="`item count ${item.id}`"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.quantity"
                      type="number"
                      :placeholder="$t('g.placeholder.enterTheCountHere')"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- ** Count -->

              <!-- Remove Button -->
              <b-col md="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>{{ $t("g.delete") }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <!-- <hr /> -->
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- ** Item prices -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";

import {
  BRow,
  BForm,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BFormTextarea,
  BButton,
  BCardTitle,
  BBreadcrumb,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BFormTextarea,
    BButton,
    BCardTitle,
    BBreadcrumb,
    BFormDatepicker,
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  data() {
    return {
      form_data: {
        lab_id: null,
        supplier_name: null,
        phone_number: null,
        email: null,
        last_response_date: null,
        buy_request_type_id: null,
        proof: null,
        buy_request_line: [
          {
            desc: null,
            spec: null,
            quantity: null,
          },
        ],
      },
      lab_name_options: [
        {
          value: null,
          name: this.$t("g.selectLabName"),
          disabled: true,
        },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },

        {
          text: this.$t(`g.addServicePurchaseRequest`),
          active: true,
        },
      ],
      type_of_request_options: [
        {
          value: null,
          name: this.$t("g.selectTypeOfRequest"),
          disabled: true,
        },
      ],
      nextTodoId: 1,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    // Resize the page when created
    window.addEventListener("resize", this.initTrHeight);
    this.getLabs();
    this.getBuyRequestTypes();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    getLabs() {
      this.$http
        .get("admin/labs", {
          params:{
            user_labs:"true"
          }
        })
        .then((res) => {
          this.lab_name_options.push(...res.data.data);
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    getBuyRequestTypes() {
      this.$http
        .get("admin/buyRequestTypes")
        .then((res) => {
          this.type_of_request_options.push(...res.data.data);
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "buyRequests");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.card.scrollHeight);
      });
    },
    repeateAgain() {
      this.form_data.buy_request_line.push({
        desc: null,
        spec: null,
        quantity: null,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      let items = this.form_data.buy_request_line;
      if (items.length <= 1) {
        this.makeToast(
          "info",
          this.$t("g.cannotDeleteTheLastItem"),
          this.$t("g.note")
        );
      } else {
        items.splice(index, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight);
      }

      // Returns the id value of the initial value
      if (items.length <= 0) {
        this.nextTodoId = 0;
      } else {
        this.nextTodoId = items.length - 1;
      }

      // Rearrange id after random deletion
      items.forEach((el, index) => {
        el.id = index;
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "buy_request_line") {
          form[i] = [
            {
              desc: null,
              spec: null,
              quantity: null,
            },
          ];
        }
      }
    },
    validateForm() {
      console.log(this.form_data);
      this.$refs.SPRForm.validate().then((success) => {
        if (success) {
          this.submitData();
          //  this.clearForm();
          this.$refs.SPRForm.reset();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    submitData() {
      let formData = new FormData();
      for (let item in this.form_data) {
        if (item === "buy_request_line") {
        } else {
          formData.append(item, this.form_data[item]);
        }
      }

      for (const key in this.form_data.buy_request_line) {
        console.log(key);
        formData.append(
          `buy_request_line[${key}][desc]`,
          this.form_data.buy_request_line[key].desc
        );
        formData.append(
          `buy_request_line[${key}][spec]`,
          this.form_data.buy_request_line[key].spec
        );
        formData.append(
          `buy_request_line[${key}][quantity]`,
          this.form_data.buy_request_line[key].quantity
        );
      }

      this.$http
        .post("admin/buyRequests", formData)
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: 'ServicesPurchaseRequestList' });
          }, 500);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
